import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { SEO } from "gatsby-theme-core";
import { Layout, HeaderOne, FooterOne } from "gatsby-theme-layout";
import {
    HeroSection03,
    VideoStreamingContainerThree,
} from "gatsby-theme-container";

const StreamingIndex = ({ data }) => (
    <Layout>
        <SEO pageName="Horro Movies" />
        <HeaderOne data={{ ...data?.header, ...data.navigation }} />
        <HeroSection03
            data={{
                items: data?.horrorMovieSlider?.nodes,
            }}
        />
        <VideoStreamingContainerThree
            data={{
                items: data?.horrorMoviePost?.nodes,
            }}
        />
        <FooterOne data={{ ...data?.footer, ...data?.contact }} />
    </Layout>
);

export const query = graphql`
    query StreamingHorrorMovieQuery {
        header: general(section: { eq: "header-01" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu-streaming" }) {
            ...Menu01
        }
        footer: general(section: { eq: "footer-01" }) {
            ...Footer01
        }
        contact: general(section: { eq: "contact" }) {
            ...Contact01
        }
        page(title: { eq: "streaming-home-two" }) {
            content {
                ...PageContent
            }
        }
        horrorMovieSlider: allVideoStreamingMovie(
            sort: { fields: date, order: DESC }
            filter: {
                category: { elemMatch: { slug: { eq: "horror" } } }
                is_featured: { eq: true }
            }
        ) {
            nodes {
                ...VideoStreamingMovie
            }
        }
        horrorMoviePost: allVideoStreamingMovie(
            sort: { fields: date, order: DESC }
            filter: { category: { elemMatch: { slug: { eq: "horror" } } } }
        ) {
            nodes {
                ...VideoStreamingMovie
            }
        }
    }
`;

StreamingIndex.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.shape({}),
        navigation: PropTypes.shape({}),
        footer: PropTypes.shape({}),
        contact: PropTypes.shape({}),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        horrorMovieSlider: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        horrorMoviePost: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export default StreamingIndex;
